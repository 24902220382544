.wht-header {
    width: 100%;
    height: 70px;
    background-color: #5E335A;
}

.pageHeader {
    background-color: #C4922C;
    width: 100%;
    color: white;
    font-family: 'dutch-mediaeval-pro', sans-serif;
    font-weight: bold;
    padding: 10px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
}

.pageHeader p {
    width: 100%;
    align-items: center;
    text-align: center !important;
    margin-top: 5px;
    margin-bottom: 5px;
}

.menuContainer {
    background-color: #C4922C;
    position: fixed;
    top: 70px;
    width: 100%;
    color: white;
    font-family: 'dutch-mediaeval-pro', sans-serif;
    font-weight: bold;
    padding: 10px;
    z-index: 12;
    font-size: 20px;
    justify-content: center;
    display: flex;
}

.menuContainer p {
    cursor: pointer
}


.menuContainer hr {
    border-top: solid 2px #A46540;
    width: 130%;
    margin-left: -30px
}

.whr-content {
    overflow-y: auto;
    width: 100%;
    height: 100%;
    margin-bottom: 85px;
    /* max-height: calc(100vh - 200px); */
}

.center {
    margin-left: auto;
    margin-right: auto;
}

/**Home**/

.homeContainer {
    width: 100%;
    height: 100%;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
}

/* .homeContainer * {
    border: 1px solid red;
} */

.homeContainer img {
    width: 100%;
    margin-bottom: 20px;
}

.homeHeader {
    max-width: 100%;
    background: url('./assets/whr-header.svg');
    background-size: 100% auto;
    background-repeat: no-repeat;
    width: 100%;
    height: calc(0.4*100vw);
    max-height: 220px;
    margin-bottom: 20px;
}

.text1, .text2, .text3, .text4, .text5 {
    color: #5E335A;
    text-align: center !important;
    margin-bottom: 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.text1 {
    line-height: 25px;
    font-size: 22px;
    width: 60%;

    text-align: center !important;
    margin-bottom: 20px;
    margin-right: auto;
    margin-left: auto;

    font-family: 'dutch-mediaeval-pro', sans-serif;
}

.text2 {
    color: #5E335A;
    font-size: 18px;
    text-align: center !important;
    font-weight: bold;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    line-height: 25px;
    margin-bottom: 20px;

}

.text3 {
    color: #5E335A;
    font-size: 16px;

    width: 90%;
    margin-right: auto;
    margin-left: auto;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    line-height: 25px;
    padding-bottom: 40px;
    text-align: center;
}

.text2 {
    font-size: 18px;
    font-weight: bold;
    width: 90%;
    line-height: 25px;

}

.text3 {
    width: 90%;
}

.text4 {
    width: 60%;
}

.text5 {
    width: 100%;
}

.text6 {
    font-family: 'dutch-mediaeval-pro', sans-serif;
    font-size: 32px;
    font-weight: bold;
}

.footer {
    width: 100%;
    height: 80px;
    position: fixed;
    bottom: 0;
    background-color: rgb(94, 51, 90);
    color: white;
}

.whr_container {
    display: flex;
    padding: 0rem !important;
    margin: 0rem !important;
    position: relative;
    height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow-y: hidden;
    z-index: 11
}

.close_button {
    cursor: pointer;
    width: 1.5rem;
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    z-index: 12
}