.rcw-conversation-container {
    box-shadow: none;
    border-bottom-left-radius: 0%;
    border-bottom-right-radius: 0%;
}

.rcw-sender {
    align-items: center;
    border-bottom-left-radius: 0%;
    border-bottom-right-radius: 0%;
    background-color: #e1e1e1;
}

.rcw-picker-btn {
    display: none;
}

.rcw-new-message {
    width: calc(100% - 70px);
    border-radius: 8px 0 0 8px;
}

.rcw-send::before {
    content: "Send";
}


.rcw-send {
    height: 40px;
    width: 70px;
    background-color: #2C3C1A !important;
    color: white;
    border-radius: 0 8px 8px 0;
}

.rcw-send .rcw-send-icon {
    display: none;
}

.rcw-conversation-container>.rcw-header {
    background-color: #2C3C1A !important;
    padding: 5px 0 5px !important;
}

.rcw-client>.rcw-message-text {
    background-color: #2C3C1A !important;
    color: white !important
}

.rcw-open-launcher {
    animation-duration: 0ms;
}

.rcw-launcher {
    display: flex;
    align-items: center;
    background-color: transparent;
    width: 16rem;
    height: 2.5rem;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    border-bottom-left-radius: 0%;
    border-bottom-right-radius: 0%;
    box-shadow: none;
    position: fixed;
    bottom: 6.2vh;
    right: 0;
}


.rcw-launcher>img {
    background-color: none;
    width: 100%;
}

.rcw-launcher>span{
    z-index: 50 !important;
}

.rcw-response>.rcw-message-text, .rcw-input {
    color: black;
}

.rcw-close {
    width: 20px !important;
}

.rcw-conversation-container .rcw-close-button {
    display: block !important;
    background-color: transparent !important;
    border: 0;
    display: block;
    position: absolute;
    right: 10px;
    top: 20px;
    width: 40px;
    box-shadow: none;
}

.rcw-widget-container {
    margin: 0 2rem 0 0;
    bottom: 3rem;
    z-index: 1;
    width: 30vw;
}

.rcw-title {
    text-align: start;
    margin-left: 20px !important
}

.rcw-close-widget-container{
    z-index: 0 !important;
}


@media screen and (max-width:1200px)  {
    /* .rcw-launcher {
        margin-bottom: 42px;
        width: 350px;
    } */
}

@media screen and (max-width:800px) {

    .rcw-conversation-container .rcw-title {
        padding: 15px 0;
        font-size: 20px;
    }

    .rcw-widget-container {
        bottom: 0;
        display: flex;
        flex-direction: column;
        margin: 0 !important;
        position: fixed;
        height: 70vh !important;
        right: 0;
        width: 90vw !important;
        bottom: 40px;
    }
    

    .rcw-conversation-container {
        width: 100% !important;
        max-width: none;
        min-width: auto;
    }

    .rcw-conversation-container.active {
        opacity: 1 !important;
        transform: translateY(0) !important;
        transition: opacity 0.3s ease, transform 0.3s ease !important;
        height: 100% !important;
        width: 100% !important;
        min-width: auto;
    }

    .rcw-launcher {
        display: flex;
        align-items: center;
        background-color: transparent;
        width: 5rem !important;
        height: 5rem !important;
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
        border-bottom-left-radius: 0%;
        border-bottom-right-radius: 0%;
        box-shadow: none;
        margin: 10px;
        bottom: 2.5rem;
    }
}


.rcw-close-launcher, .rcw-hide-sm {
    display: none;
}

.rcw-response .rcw-message-text {
    background-color: ##2C3C1A;
    border-radius: 10px;
    max-width: 350px;
    padding: 15px;
    text-align: left;
}

