.hak-image-box {
  display: flex;
  flex-flow: column;
  position: absolute;
  top: 0%;
  left: 2%;
  right: 2%;
  bottom: 2%;

  z-index: 2;
}

.hak-image-box .hak-image-row {
  border: 1px solid grey;
}

.hak-image-box .hak-image-row.header {
  flex: 0 1 auto;
}

.hak-image-box .hak-image-row.content {
  /* flex: 1 1 auto; */
  overflow: scroll;
  background-color: white;
}

.hak-image-row.content {
  position: relative;
}

.hak-image-row.content div {
  position: absolute;
  height: 100px;
  width: 300px;
}

.hak-image-box .hak-image-row.footer {
  background-color: rgb(158, 165, 173);
  flex: 0 1 20px;
}

.hak-modal-folder {
  position: absolute;
  top: 1%;
  left: 2%;
  right: 2%;
  bottom: 2%;
  z-index: 1;
  margin-bottom: 5vh;
  background-color: white;
}

.hak-modal-video {
  overflow: scroll;
  position: absolute;
  top: 7%;
  left: 0%;
  right: 0%;
  bottom: 7%;
  z-index: 1;
  border-radius: 5px;
  background-color: rgb(158, 165, 173);
  border: 4px solid rgb(69, 76, 82);
  box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.59);
  -webkit-box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.59);
  -moz-box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.59);
}

.hak-modal-video-contact {
  overflow: scroll;
  position: absolute;
  top: 7%;
  left: 0%;
  right: 5%;
  bottom: 0%;
  z-index: 1;
  border-radius: 5px;
  background-color: rgb(158, 165, 173);
  border: 4px solid rgb(69, 76, 82);
  box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.59);
  -webkit-box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.59);
  -moz-box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.59);
}

.hak-modal-password {
  position: absolute;
  top: 30%;
  left: 35%;
  right: 35%;
  z-index: 1;
  border-radius: 5px;
  background-color: rgb(158, 165, 173);
  border: 4px solid rgb(69, 76, 82);
}

.hak-modal-audio {
  position: absolute;
  top: 30%;
  left: 30%;
  right: 30%;
  z-index: 1;
  border-radius: 5px;
  background-color: rgb(158, 165, 173);
  border: 4px solid rgb(69, 76, 82);
}

.hak-audioplayer {
  margin-bottom: "100px";
  height: auto;
}

.hak-modal-txt {
  background-color: white;
  overflow: hidden;
  position: absolute;
  top: 15%;
  left: 20%;
  right: 20%;
  border-radius: 5px;
  z-index: 1;
  border: 1px solid #888888;
  box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.2);
  -webkit-box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.2);
  -moz-box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.2);
}

.hak-modal:focus {
  outline: none;
}

.hak-modal-audio:focus {
  outline: none;
}

.hak-modal-pdf:focus {
  outline: none;
}

.hak-modal-txt:focus {
  outline: none;
}

.hak-modal-password:focus {
  outline: none;
}

.hak-modal-header {
  display: flex;
  align-items: center;
  background-color: #b3b3b3;
  height: 35px;
  color: black;
  font-weight: 500;
  cursor: move;
}

.hak-modal-header-vumail {
  background-color: #2E3191;
  height: 35px;
  color: white;
  cursor: move;
}

.hak-modal-header-close {
  height: 35px;
  width: 35px;
  float: right;
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hak-form {
  padding: 1.5em;
  width: 80%;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-message {
  color: red;
}

.hak-it-password-container {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.hak-it-password-inputBox {
  padding: 12px 20px;
  margin: 4px 4px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  width: 35px !important;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}


@media screen and (max-width:1082px) {

  .hak-modal-password, .hak-modal-audio, .hak-modal-txt, .hak-modal-pdf, .hak-modal-folder {
    left: 0%;
    top: 0%;

    right: 0%;
  }

  .hak-modal-folder {
    left: 0%;
    top: 0%;
    bottom: 0%;
    right: 0%;
  }
}